import { tokenPortal, axiosPortal, followupTokenPortal, axiosPortalOther ,postwithXApikey} from "./axios";

export const PostTokenApi = async (url: string, data: object): Promise<any> => {
  return tokenPortal.post(url, data);
};

export const PostPortalAPi = async (
  url: any,
  data: object
): Promise<any> => {
  return axiosPortal.post(url, data);
};

export const PostPortalAPiOther = async (
  url: any,
  data: object
): Promise<any> => {
  return axiosPortalOther.post(url, data);
};
export const GetPortalApi = async (
  url: any,
): Promise<any> => {
  return axiosPortalOther.get(url);
};
export const PatchPortalApi = async (
  url: any,
  data: any
): Promise<any> => {
  return axiosPortalOther.patch(url, data);
};



export const PostTokenFollowupApi = async (url: string, data: object): Promise<any> => {
  return followupTokenPortal.post(url, data);
};

export const PatchTokenApi = async (url: string, data: object): Promise<any> => {
  return tokenPortal.patch(url, data);
};

export const PostTokenFollowupApiFileDownload = async (url: string, data: object): Promise<any> => {
  return followupTokenPortal.post(url, data, { responseType: 'arraybuffer'});
};

//for post request along with x-api-key in headers
export const PostWithXApikey = async (url: string, data: object): Promise<any> => {
  return postwithXApikey.post(url, data);
};