import { GET_DEFAULT_SERVICE_DATA, GET_SERVICE_DATA } from "../../actions/LeadDetailsPage/leadDetialsHeaderSelect.actions";

const inititalState={
    defaultSelectData:{label:"",value:""},
    allSelectedData:[{label:"Select Service",value:"0"}]
};

export const leadDetailsHeaderSelectBoxReducers = (state = inititalState,action:any)=>{
switch (action.type) {
    case GET_SERVICE_DATA:
        return{
            ...state,
            allSelectedData:action.data
        }
        ;
    case GET_DEFAULT_SERVICE_DATA:
        return{
            ...state,
            defaultSelectData:action.data
        }

    default:
        return state;
}
}