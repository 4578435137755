import { GET_POTENTIAL_LEAD_DATA, POTENTIAL_ISLOADING_FALSE, POTENTIAL_ISLOADING_TRUE } from "../../actions/Lead_lists/potentialLeads.actions";

const initialState = {
    potentialLeadData: [],
    isLoading: false
};
export const potentialLeadReducers = (state = initialState, action: any) => {
    switch (action.type) {
        case POTENTIAL_ISLOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case POTENTIAL_ISLOADING_FALSE:
            return {
                ...state,
                isLoading: false
            }
        case GET_POTENTIAL_LEAD_DATA:
            return {
                ...state,
                potentialLeadData: action.data
            };
        default:
            return state;
    }
}