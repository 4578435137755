import "../styles/globals.css";
import type { AppProps } from "next/app";
import { wrapper } from "../redux/store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "../i18nextInit";
import { PostPortalAPiOther, PostTokenApi } from "../utils/Api/commonApiCalls";
import { useEffect, useState } from "react";
import { authApi } from "../utils/constant";
import { axiosPortalOtherLogout } from "../utils/Api/axios";
import * as CryptoJS from 'crypto-js'
// import { ApiProvider } from '@sd/ui-core';
import { setUlaData } from "../utils/Api/setUla";
import { useRouter } from "next/router";
import PopUp from "../components/commonComponents/PopUp";
import Button from "../components/commonComponents/Button";
import { min } from "lodash";
import Head from 'next/head'; 

function MyApp({ Component, pageProps }: AppProps) {
  const [isUserLogin , setUSerLogin] = useState<any>(true)
  const [logoutMessage , setLogoutMessage] = useState("You are logout due to inactivity.")
  const [showPopup , setShowPopup] = useState(false)
  const popupKey: any = process.env.NEXT_PUBLIC_SHOW_LOGOUT_NOTIFICACATION
  if (process.env.NEXT_PUBLIC_CONSOLE_LOG === "0") {
    console.log = function () { };
  }
  const router = useRouter()

  let timeInterval: any = ""
  const isLoginPage = router.pathname == "/login" || router.pathname == "/defaultLogin"
  useEffect(() => {
 
if(router.pathname != "/[leadId]"){
  console.log(router.pathname, "i am router")
  timeInterval = setInterval(async () => {
    let intervalTime: any = localStorage.getItem("intervalTime")! ? localStorage.getItem("intervalTime") : "1"
    let lastAcivity: any = localStorage.getItem('ula')
    const bytes = await CryptoJS.AES.decrypt(lastAcivity, process.env.NEXT_PUBLIC_SECRET_KEY!);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    const lastAcivityDate = data
  if (lastAcivity) {
    let diffMs: any = Math.abs(new Date(lastAcivityDate) - new Date()); // milliseconds between now & last activity
    let seconds = Math.floor((diffMs / 1000));
    let minute = Math.floor((seconds / 60));
    console.log(minute >= (14 - +popupKey) ,popupKey,process.env.NEXT_PUBLIC_SHOW_LOGOUT_NOTIFICACATION, typeof process.env.NEXT_PUBLIC_SHOW_LOGOUT_NOTIFICACATION, typeof +process.env.NEXT_PUBLIC_SHOW_LOGOUT_NOTIFICACATION ,"testtttttt" , minute)
    if(minute >= (14 - +popupKey)) {
      setShowPopup(true)
    }
    if (minute  >=  14) {
      setUSerLogin(false)
      clearInterval(timeInterval)
      // await PostTokenApi(authApi.EXPIRE_TOKEN, {})
      localStorage.clear();
      document.cookie = "sdUserSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      const logoutUrl: any = process.env.NEXT_PUBLIC_COMMON_MASTER_LOGOUT
      await axiosPortalOtherLogout.post(logoutUrl, {})
    }
  }
    if (+intervalTime % 15 === 0) {
      localStorage.setItem("intervalTime", "1")
      const tokenData = await PostPortalAPiOther(process.env.NEXT_PUBLIC_GENERATE_TOKEN, {})
      if(tokenData.data) {
        localStorage.setItem("aws-token", tokenData.data.data.accessToken)
      }else {
        setLogoutMessage(tokenData.response?.data?.code ? tokenData.response.data.code : "Error in generating token")
        setUSerLogin(false)
        // await PostTokenApi(authApi.EXPIRE_TOKEN, {})
        localStorage.clear();
        document.cookie = "sdUserSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        const logoutUrl: any = process.env.NEXT_PUBLIC_COMMON_MASTER_LOGOUT
        await axiosPortalOtherLogout.post(logoutUrl, {})
      }

    } else {
      localStorage.setItem("intervalTime", JSON.parse(intervalTime) + 1)
    }
  }, 60000)
}

}, [])
  
// useEffect(() => {
  //   ApiProvider.registerBeforeApiInvokeCallback(async function () {
   //    await setUlaData()
  //   }) }, [])

  return (
    <>
    {
        process.env.NEXT_PUBLIC_SHOW_CHATBOT == "1" && isUserLogin &&  <Head> {/* Add the Head component to include elements in the head section */}
        <link rel="preconnect" href={process.env.NEXT_PUBLIC_CHATBOT_CLOUDFRONT_URL_PRE1} />
        <link rel="preconnect" href={process.env.NEXT_PUBLIC_CHATBOT_CLOUDFRONT_URL_PRE2} />
      </Head>
    }
      {
        isUserLogin ?   <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {
            showPopup ? <PopUp
              onClick={setShowPopup}
            >
              <div className="mb-6 mt-4">Your session is going to expire in {process.env.NEXT_PUBLIC_SHOW_LOGOUT_NOTIFICACATION} minutes. Do you wish to continue the session?</div>
              <div className="flex justify-center items-center">
                <Button custom="mr-3 text-base" type="submit" tittle="Yes" onClick={() => {
                  window.location.reload()
                }}/> 
                <Button custom="bg-[#de0039] text-base" type="submit" tittle="No" onClick={async () => {
                    clearInterval(timeInterval)
                    // await PostTokenApi(authApi.EXPIRE_TOKEN, {})
                    localStorage.clear();
                    document.cookie = "sdUserSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                    const logoutUrl: any = process.env.NEXT_PUBLIC_COMMON_MASTER_LOGOUT
                    await axiosPortalOtherLogout.post(logoutUrl, {})
                    location.href = process.env.NEXT_PUBLIC_LOGOUT_REDIRECT!

                }}/>
              </div>
            </PopUp> : <Component {...pageProps} />
          }
        </> :  
      <>
        <div id="myModal" className="modal">
          <div className="modal-content">
            <div className="flex flex-col justify-center items-center modal-box">
              <div className="modal-header">Please login again</div>
              <div className="modal-text">{logoutMessage}</div>
              <div className="modal-footer">
                <button className="btn selectleadblock__button" onClick={() => {
                      location.href = process.env.NEXT_PUBLIC_LOGOUT_REDIRECT!
                    }}>Login</button>
              </div>
            </div>
          </div>
        </div>
      </>
      }
     {process.env.NEXT_PUBLIC_SHOW_CHATBOT == "1" && isUserLogin && <script type="text/javascript" src={process.env.NEXT_PUBLIC_CHATBOT_CLOUDFRONT_URL_JWT} nonce='daveai'></script>}
      {process.env.NEXT_PUBLIC_SHOW_CHATBOT == "1" && isUserLogin && <script type="text/javascript" src={process.env.NEXT_PUBLIC_CHATBOT_CLOUDFRONT_URL_ENV} nonce='daveai'></script>}
      {process.env.NEXT_PUBLIC_SHOW_CHATBOT == "1" && isUserLogin && <div className="dave-main-container" id="dave-settings" data-dave-components="chatbot-help,js/index.js" data-dave-version="9.1"></div>}
      {process.env.NEXT_PUBLIC_SHOW_CHATBOT == "1" && isUserLogin &&  <div className="dave-help-container" id="dave-help" data-timeout={15000}></div>}
     { process.env.NEXT_PUBLIC_SHOW_CHATBOT == "1" && isUserLogin && <script type="text/javascript" defer={true} src={process.env.NEXT_PUBLIC_CHATBOT_CLOUDFRONT_URL_LIB} nonce='daveai'></script>}
    </>
  );
}

export default wrapper.withRedux(MyApp);
