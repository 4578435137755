export const POTENTIAL_ISLOADING_TRUE ='POTENTIAL_ISLOADING_TRUE';
export const POTENTIAL_ISLOADING_FALSE ='POTENTIAL_ISLOADING_FALSE';
export const GET_POTENTIAL_LEAD_DATA ='GET_POTENTIAL_LEAD_DATA';

export const potentialIsLoadingTrue =()=>{
    return {
        type:POTENTIAL_ISLOADING_TRUE
    }
};

export const potentialIsLoadingFalse =()=>{
    return {
        type:POTENTIAL_ISLOADING_FALSE
    }
};

export const getPotentialLeadsData = (data:any)=>{
    return{
        type:GET_POTENTIAL_LEAD_DATA,
        data:data
    }
}
