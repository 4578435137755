import { LOADING_TRUE, LOADING_FALSE } from "../types/loading.types"

export const loadingState = {
    loading: false
}

export const loadingReducer = (state = loadingState, action: {type: string}) => {
    switch (action.type) {
        case LOADING_TRUE:
            return {
                ...state,
                loading: true
            }

        case LOADING_FALSE:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}