export const GET_FOLLOW_UP_LEAD = 'GET_FOLLOW_UP_LEAD';
export const REMOVE_FOLLOW_UP_DATA = 'REMOVE_FOLLOW_UP_DATA';
export const ADD_FOLLOW_UP_DATA = 'ADD_FOLLOW_UP_DATA';
export const FOLLOWUP_ISLOADING_TRUE ='FOLLOWUP_ISLOADING_TRUE';
export const FOLLOWUP_ISLOADING_FALSE ='FOLLOWUP_ISLOADING_FALSE';
export const SET_FOLLOWUP_ITEM_PER_PAGE ='SET_FOLLOWUP_ITEM_PER_PAGE';

export const getFollowUpData = (data:any)=>{
    return{
        type:GET_FOLLOW_UP_LEAD,
        data:data
    }
}

export const removeFolloUpData = (data: any) => {
    return {
        type: REMOVE_FOLLOW_UP_DATA,
        data: data
    }
} 

export const addFollowUpData = (data: any) => {
    return {
        type: ADD_FOLLOW_UP_DATA,
        data: data
    }
};

export const followUpIsLoadingTrue =()=>{
    return {
        type:FOLLOWUP_ISLOADING_TRUE
    }
};

export const followUpIsLoadingfalse =()=>{
    return {
        type:FOLLOWUP_ISLOADING_FALSE
    }
}

export const setFollowupItemPerPage = (data: any) => {
    return ({
        type: SET_FOLLOWUP_ITEM_PER_PAGE,
        data: data
    });
}