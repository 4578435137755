export const LOST_ISLOADING_TRUE ='LOST_ISLOADING_TRUE';
export const LOST_ISLOADING_FALSE ='LOST_ISLOADING_FALSE';
export const GET_LOST_LEAD_DATA ='GET_LOST_LEAD_DATA';

export const lostIsLoadingTrue =()=>{
    return {
        type:LOST_ISLOADING_TRUE
    }
};

export const lostIsLoadingFalse =()=>{
    return {
        type:LOST_ISLOADING_FALSE
    }
};

export const getLostLeadsData = (data:any)=>{
    return{
        type:GET_LOST_LEAD_DATA,
        data:data
    }
}
