import Image from "next/image";
import React from "react";

const PopUp: React.FC<{
  transparent?: boolean;
  bigsize?:boolean;
  IsSubmit?:boolean;
  
  onClick:React.Dispatch<React.SetStateAction<boolean>>;
  parentState?:React.Dispatch<React.SetStateAction<boolean>>
}> = ({ transparent, onClick, children, bigsize,parentState,IsSubmit}): JSX.Element => {
  const classes = ["popup__content slidein--down", transparent && "bg-transparent", bigsize && "popup__content--bigsize"];
  document.addEventListener("click",(e)=>{
    const currentElement = e.target;
    const outerElement = document.getElementById("popup")
    currentElement === outerElement && onClick(false)
    currentElement === outerElement && parentState && parentState(false)
  })
  return (
    <>
      <div className="popup" id="popup">
        {/* <div className="absolute top-0 left-0 w-screen h-screen bg-red-500"  id="popup"> */}
        <div className={classes.join(" ")}>
          
           <button
          onClick={()=>{
            onClick(false)
            parentState && parentState(false)
          }}
          >
            {!IsSubmit &&  <div className={[transparent?"crossbutton--white":"crossbutton"].join(' ')}>
              <Image
                src={transparent? "/assets/images/ViewLeads/filter-close__white.svg":"/assets/images/ViewLeads/filter-close.svg"}
                alt="filtericon"
                width={17}
                height={17}
                
              />
            </div> }
           
          </button>
          <div>{children}</div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default PopUp;
