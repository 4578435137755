import { MSPIN } from "../types/login.types"

export const intitalLoginState = {
    loginTimer: '00:00'
}

export const loginReducer = (state = intitalLoginState, action: {type: string, payload: {loginExpireCounter: string}}) => {
    switch (action.type) {
        case MSPIN:
            return {
                ...state,
                loginTimer: action.payload.loginExpireCounter
            }

        default:
            return state;
    }
}