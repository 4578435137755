import React from "react";
import { buttonPropsTypes } from "../../types/commonComponents/buttonTypes";

const Button: React.FC<buttonPropsTypes> = ({
  tittle,
  type,
  simple,
  custom,
  getFieldProps,
  disable,
  onClick,
  outlined,
  cropText,
}): JSX.Element => {
 
  const classes = [
    "btn",
    outlined && 'btn--outlined',
    simple && "btn--simple",
    custom,
    disable && "bg-opacity-50",
  ];
  return (
    <>
      <button className={classes.join(" ")} type={type} disabled={disable}
        onClick={onClick}
      >
        <p className={cropText ? (`overflow-hidden text-ellipsis whitespace-nowrap`) : ""} title={tittle}>{tittle}</p>
      </button>
    </>
  );
};

export default Button;
