export const GET_TO_DO_LEADS = 'GET_TO_DO_LEADS';
export const REMOVE_ELEMENT = 'REMOVE_ELEMENT';
export const TO_DO_ISLOADING_TRUE = 'TO_DO_ISLOADING_TRUE';
export const TO_DO_ISLOADING_FALSE = 'TO_DO_ISLOADING_FALSE';
export const SET_TODO_ITEM_PER_PAGE = 'SET_TODO_ITEM_PER_PAGE';

export const getToDoLeadsData = (data: any) => {
    return ({
        type: GET_TO_DO_LEADS,
        data: data
    });
}

export const removeLeadsData = (data: any) => {
    return ({
        type: REMOVE_ELEMENT,
        data: data
    });
}



export const toDoIsLoadingTrue = () => {
    return {
        type: TO_DO_ISLOADING_TRUE
    }
};

export const toDoIsLoadingFalse = () => {
    return {
        type: TO_DO_ISLOADING_FALSE
    }
}

export const setToDoItemPerPage = (data: any) => {
    return ({
        type: SET_TODO_ITEM_PER_PAGE,
        data: data
    });
}