import { LEAD_COUNT } from "../types/dashboard.types";

export const dashboardState = {
  totalLead: 0,
};

export const dashboardReducer = (
  state = dashboardState,
  action: { type: string; data: number }
) => {
  switch (action.type) {
    case LEAD_COUNT:
      return {
        ...state,
        totalLead: action.data,
      };
    default:
      return state;
  }
};
