export const getDateRange = () => {
    let currentDate = new Date();
    let firstDay = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
    );

    const offset = currentDate.getTimezoneOffset();
    currentDate = new Date(currentDate.getTime() - offset * 60 * 1000);
    firstDay = new Date(firstDay.getTime() - offset * 60 * 1000);
    return {
        fromDate: firstDay.toISOString().split("T")[0],
        toDate: currentDate.toISOString().split("T")[0],
    }
}
export const getLastThreeMonthDateRange = () => {
    let currentDate = new Date();
    const offset = currentDate.getTimezoneOffset();

    let fromDate = new Date(currentDate.getTime() - 90 * 24 * 60 * 60 * 1000);

    currentDate = new Date(currentDate.getTime() - offset * 60 * 1000);
    fromDate = new Date(fromDate.getTime() - offset * 60 * 1000);

    return {
        fromDate: fromDate.toISOString().split("T")[0],
        toDate: currentDate.toISOString().split("T")[0],
    };
};
