import { ADD_QUALIFY_LEADS_DATA, GET_QUALIFY_LEADS_DATA, QUALIFY_ISLOADING_FALSE, QUALIFY_ISLOADING_TRUE, SET_QUALIFY_ITEM_PER_PAGE } from "../../actions/Lead_lists/qualifyLeads.actions";

const initialState = {
    qualifyData: [],
    isLoading: false
};

export const qualifyLeadsReducers = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_QUALIFY_LEADS_DATA:
            return {
                ...state,
                qualifyData: action.data

            };
        case ADD_QUALIFY_LEADS_DATA:
            const arr: Array<any> = [...state.qualifyData]
            const addedVal: object = action.data
            arr.unshift(addedVal)
            return {
                ...state,
                qualifyData: arr
            };
        case QUALIFY_ISLOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case QUALIFY_ISLOADING_FALSE:
            return {
                ...state,
                isLoading: false
            }
        case SET_QUALIFY_ITEM_PER_PAGE:
            return {
                ...state,
                itemPerPage: action.data
            }
        default:
            return state;
    }

}