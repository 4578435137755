import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./public/assets/locales/en/translation.json";
import translationDE from "./public/assets/locales/de/translation.json";
import translationHI from "./public/assets/locales/hi/translation.json";
import translationIDN from "./public/assets/locales/hun/translation.json";
import translationHUN from "./public/assets/locales/hun/translation.json";
import Cache from "i18next-localstorage-cache";

const newInstance1 = i18n.createInstance();



const availableLanguages = ["en", "de", "hi", "idn", "hun"];

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  hi: {
    translation: translationHI,
  },
  hun: {
    translation: translationHUN,
  },
};

let options = {
  enabled: true,
  prefix: "i18next_res_",
  expirationTime: 7 * 24 * 60 * 60 * 1000,
  versions: {},
};

newInstance1
  .use(Cache)
  .use(initReactI18next)
  .init({
    resources,
    cache: options,
    react: {
      useSuspense: false, //
    },
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default newInstance1;
