export const GET_INITIAL_FILTERS = 'GET_INITIAL_FILTERS'
export const SET_TODO_COUNT = 'SET_TODO_COUNT'
export const SET_FOLLOWUP_COUNT = 'SET_FOLLOWUP_COUNT'
export const SET_QUALIFIED_COUNT = 'SET_QUALIFIED_COUNT'



export const setInitialFilterValue = (data:any)=>{
    return {
        type:GET_INITIAL_FILTERS,
        data:data
    }
}

export const setToDoCount = (data:number)=>{
    return{
        type:SET_TODO_COUNT,
        data:data
    }
}

export const setFollowUpCount = (data:number)=>{

    return{
        type:SET_FOLLOWUP_COUNT,
        data:data
    }
}

export const setQualifiedCount = (data:number)=>{

    return{
        type:SET_QUALIFIED_COUNT,
        data:data
    }
}