import {
  GET_FOLLOW_UP_POP_UP,
  POST_FOLLOW_UP_POP_UP,
} from "../actions/followUpPopUp.action";
import data from "../../data/followUpPopData.json";

const initialState = {
  data: {}
};

export const followUpPopUpReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_FOLLOW_UP_POP_UP:
      return {
        ...state,
        data: action.data
      };

    case POST_FOLLOW_UP_POP_UP:
      return {
        ...state,
      };

    default:
      return state;
  }
};
