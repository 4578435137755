export const GET_FOLLOW_UP_POP_UP = 'GET_FOLLOW_UP_POP_UP';
export const POST_FOLLOW_UP_POP_UP = 'POST_FOLLOW_UP_POP_UP'


export const folloUpPopUpAction = (data: any) => {
    return {
        type: GET_FOLLOW_UP_POP_UP,
        data
    }
}

export const postFolloUpPopUpAction = (data: any) => {
    return {
        type: POST_FOLLOW_UP_POP_UP,
        data
    }
}