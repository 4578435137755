import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authApi } from "../constant";
import { PostPortalAPiOther, PostTokenApi } from "./commonApiCalls";
import * as CryptoJS from 'crypto-js'

export const axiosPortal = axios.create({
  withCredentials: true,
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosPortalOther = axios.create({
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosPortalOtherLogout = axios.create({
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

export const tokenPortal = axios.create({
  withCredentials: true,
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  timeout: 1000000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const followupTokenPortal = axios.create({
  withCredentials: true,
  baseURL: process.env.NEXT_PUBLIC_FOLLOWUP_BASE_URL,
  timeout: 1000000,
  headers: {
    "Content-Type": "application/json",
  },
})

//for post request along with x-api-key in headers
export const postwithXApikey = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-api-key" : "iueiueuyiww21"
  },
})

postwithXApikey.interceptors.request.use(
  async (data) => {
    return data;
  },
  (error) => {
    Promise.reject(error);
  }
);

postwithXApikey.interceptors.response.use(
  async (config) => {
    return config.data;
  },
  async (error) => {
    if (
      error.response.status === 403 || 
      error.response.data.message[0] === "Token not Valid" || error.response.data.message[0] === "Unauthorized"
    )  {
      // await PostTokenApi(authApi.EXPIRE_TOKEN, {})
      localStorage.clear();
      document.cookie = "sdUserSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      const logoutUrl: any = process.env.NEXT_PUBLIC_COMMON_MASTER_LOGOUT
      await axiosPortalOtherLogout.post(logoutUrl, {})
      location.href = process.env.NEXT_PUBLIC_LOGOUT_REDIRECT!


    }
    return error.response.data
    // console.log("errorrrrrrr",error.response.data)
    // Promise.reject(error);
  }
);


tokenPortal.interceptors.request.use(
  async (config) => {
    
    const token = localStorage.getItem("auth-token");
    const awsToken: any = localStorage.getItem("aws-token");
    const encryptString: any = await encryptData(`${new Date()}`)
    localStorage.setItem('ula',encryptString)
    const authToken = `bearer ${token}`;
    if (config.method !== "options") {
      config.headers = {
        ...config.headers,
        Authorization: authToken,
        "X-API-COG":awsToken
      };
    }
    return config;
  },
  (error) => {
    // Do something with error
    Promise.reject(error);
  }
);

tokenPortal.interceptors.response.use(
  (data) => {
    localStorage.setItem("auth-token", data.data?.token ?? "");
    return data;
  },
  async (error) => {
    console.log(error, "i am coming from the error")
    if (localStorage.getItem("auth-token")) {
      toast.error(`${error.response.data.message ? error.response.data.message : "Something Went Wrong"}`, {
        position: "bottom-left",
        autoClose: 5015,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (
      error.response.status === 403 || 
      error.response.data.message[0] === "Token not Valid" || error.response.data.message[0] === "Unauthorized"
    )  {
      // await PostTokenApi(authApi.EXPIRE_TOKEN, {})
      localStorage.clear();
      document.cookie = "sdUserSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      const logoutUrl: any = process.env.NEXT_PUBLIC_COMMON_MASTER_LOGOUT
      await axiosPortalOtherLogout.post(logoutUrl, {})
      location.href = process.env.NEXT_PUBLIC_LOGOUT_REDIRECT!


    }

    return error.response
  }
);

axiosPortal.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosPortal.interceptors.response.use(
  (data) => {
    console.log(data,"dattttt")
    if (data.data?.token !== "undefined") {
      // localStorage.setItem("auth-token", data.data?.token);
      // localStorage.setItem("aws-token", data.data?.aws_token);
    }

    return data;
  },
  async (error) => {
    toast.error(`${error.response?.data?.message ? error.response?.data?.message : "Something Went Wrong"}`, {
      position: "bottom-left",
      autoClose: 5015,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    if (
      error.response.status === 403 || 
      error.response.data.message[0] === "Token not Valid" || error.response.data.message[0] === "Unauthorized"
    )  {
      // await PostTokenApi(authApi.EXPIRE_TOKEN, {})
      localStorage.clear();
      document.cookie = "sdUserSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      const logoutUrl: any = process.env.NEXT_PUBLIC_COMMON_MASTER_LOGOUT
      await axiosPortalOtherLogout.post(logoutUrl, {})
      location.href = process.env.NEXT_PUBLIC_LOGOUT_REDIRECT!


    }
  }
  
);

followupTokenPortal.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("auth-token");
    const awsToken: any = localStorage.getItem("aws-token");
    const encryptString: any = await encryptData(`${new Date()}`)
    console.log(encryptString)
    localStorage.setItem('ula',encryptString)
    const authToken = `bearer ${token}`;
    if (config.method !== "options") {
      config.headers = {
        ...config.headers,
        Authorization: authToken,
        "X-API-COG":awsToken

      };
    }
    return config;
  },
  (error) => {
    // Do something with error
    Promise.reject(error);
  }
);

followupTokenPortal.interceptors.response.use(
  (data) => {
    if (data.data?.token !== undefined) {
    localStorage.setItem("auth-token", data.data?.token ?? "");
    }
    return data;
  },
  async (error) => {

    toast.error(`${error.response?.data?.message ? error.response?.data?.message : "Something Went Wrong"}`, {
      position: "bottom-left",
      autoClose: 5015,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    if (
      error.response.status === 403 || 
      error.response.data.message[0] === "Token not Valid" || error.response.data.message[0] === "Unauthorized"
    )  {
      // location.href = process.env.NEXT_PUBLIC_LOGIN_URL!
      // localStorage.removeItem("filterObj");
      // await PostTokenApi(authApi.EXPIRE_TOKEN, {})
      localStorage.clear();
      document.cookie = "sdUserSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      const logoutUrl: any = process.env.NEXT_PUBLIC_COMMON_MASTER_LOGOUT
      await axiosPortalOtherLogout.post(logoutUrl, {})
      location.href = process.env.NEXT_PUBLIC_LOGOUT_REDIRECT!
    }
    return error.response
  }
);


axiosPortalOther.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error)
    toast.error(`${error.response.data.message ? error.response.data.message : "Something Went Wrong"}`, {
      position: "bottom-left",
      autoClose: 5015,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    Promise.reject(error);
    return error
  }
);

axiosPortalOther.interceptors.response.use(
  (data) => {
    return data;
  },
  async (error) => {
    toast.error(`${error.response ? error.response.data.message ? error.response.data.message : error.response.data.code : "Something went wrong"}`, {
      position: "bottom-left",
      autoClose: 5015,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    if (
      error.response.status === 403 || 
      error.response.data.message[0] === "Token not Valid" || error.response.data.message[0] === "Unauthorized"
    )  {
      // await PostTokenApi(authApi.EXPIRE_TOKEN, {})
      localStorage.clear();
      document.cookie = "sdUserSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      const logoutUrl: any = process.env.NEXT_PUBLIC_COMMON_MASTER_LOGOUT
      await axiosPortalOtherLogout.post(logoutUrl, {})
      location.href = process.env.NEXT_PUBLIC_LOGOUT_REDIRECT!


    }
    return error
    // Promise.all([PostPortalAPiOther(`${process.env.NEXT_PUBLIC_BASE_URL}/User/external-api-error`,{error:JSON.stringify(error),country:"IDN"})],)

  }
);

axiosPortalOtherLogout.interceptors.response.use(
  (data) => {
    return data;
  },
  (error) => {

    // Promise.all([axiosPortalOtherLogout.post( `${process.env.NEXT_PUBLIC_BASE_URL}/User/external-api-error`, {error:JSON.stringify(error),country:"IDN"})])
  }
);

const encryptData = async (text: string) => {
  const data = await CryptoJS.AES.encrypt(
    JSON.stringify(text),
    process.env.NEXT_PUBLIC_SECRET_KEY!
  ).toString();

  console.log(data, "hello i am encypted")
  return data
};