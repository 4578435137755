import { REMOVE_FOLLOW_UP_DATA, GET_FOLLOW_UP_LEAD, ADD_FOLLOW_UP_DATA, FOLLOWUP_ISLOADING_TRUE, FOLLOWUP_ISLOADING_FALSE, SET_FOLLOWUP_ITEM_PER_PAGE } from "../../actions/Lead_lists/followUpLeads.actions";

const initialState = {
    followUpData: [],
    isLoading: false
};
export const followUpLeadReducers = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_FOLLOW_UP_LEAD:
            return {
                ...state,
                followUpData: action.data

            };

        case REMOVE_FOLLOW_UP_DATA:
            const id = action.data._id
            const arr = state.followUpData.filter((obj: any, index) => {
                return obj._id !== id
            })
            return {
                ...state,
                followUpData: arr


            }
        case ADD_FOLLOW_UP_DATA:
            const followUpArr: Array<any> = [...state.followUpData]
            const addedVal: object = action.data
            followUpArr.unshift(addedVal)
            return {
                ...state,
                followUpData: followUpArr
            }

        case FOLLOWUP_ISLOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case FOLLOWUP_ISLOADING_FALSE:
            return {
                ...state,
                isLoading: false
            }
        case SET_FOLLOWUP_ITEM_PER_PAGE:
            return {
                ...state,
                itemPerPage: action.data
            }
        default:
            return state;
    }
}