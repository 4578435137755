

export const GET_QUALIFY_LEADS_DATA = 'GET_QUALIFY_LEADS_DATA'
export const ADD_QUALIFY_LEADS_DATA = 'ADD_QUALIFY_LEADS_DATA'
export const QUALIFY_ISLOADING_TRUE = 'QUALIFY_ISLOADING_TRUE'
export const QUALIFY_ISLOADING_FALSE = 'QUALIFY_ISLOADING_FALSE'
export const SET_QUALIFY_ITEM_PER_PAGE = 'SET_QUALIFY_ITEM_PER_PAGE'

export const getQualifyData =(data:any)=>{
    return{
        type:GET_QUALIFY_LEADS_DATA,
        data:data
    }
}


export const addQualifyData =(data:any)=>{
    return{
        type:ADD_QUALIFY_LEADS_DATA,
        data:data
    }
}

export const qualifyIsLoadingTrue = ()=>{
    return {
        type:QUALIFY_ISLOADING_TRUE
    }
};

export const qualifyIsLoadingFalse= ()=>{
    return {
        type:QUALIFY_ISLOADING_FALSE
    }
}

export const setQualifyItemPerPage = (data: any) => {
    return ({
        type: SET_QUALIFY_ITEM_PER_PAGE,
        data: data
    });
}