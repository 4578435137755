import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from "redux";
import { createWrapper } from "next-redux-wrapper";
import { composeWithDevTools } from "redux-devtools-extension";
import { loginReducer as login } from "../reducers/login.reducer";
import { loadingReducer } from "../reducers/loading.reducer";
import { dashboardReducer } from "../reducers/dashboard.reducers";
import {qualifyLeadsReducers} from "../reducers/Lead_Lists/qualifyLeads.reducers"
import {toDo_Lead_Reducer} from "../reducers/Lead_Lists/toDo_Leads.reducers"
import {followUpLeadReducers} from "../reducers/Lead_Lists/followUpLeads.reducers"
import {lostLeadReducers} from "../reducers/Lead_Lists/lostLeads.reducers"
import { dateRangeReducer } from "../reducers/dateRange.reducer";
import {followUpPopUpReducer} from "../reducers/followUpPop.reducers";
import {filtersReducers} from "../reducers/filters.Reducers";
import {leadDetailsHeaderSelectBoxReducers} from "../reducers/LeadDetailPage/leadDetailsHeaderSelectBox.reducers"
import { potentialLeadReducers } from "../reducers/Lead_Lists/potentialLeads.reducers";
export const rootReducer: any = combineReducers({
  login,
  loadingReducer,
  dashboardReducer,
  qualifyLeadsReducers,
  toDo_Lead_Reducer,
  followUpLeadReducers,
  lostLeadReducers,
  potentialLeadReducers,
  dateRangeReducer,
  followUpPopUpReducer,
  filtersReducers,
  leadDetailsHeaderSelectBoxReducers  
});

const initStore = () => {
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware())
  );
  return store;
};

export const wrapper = createWrapper(initStore);
