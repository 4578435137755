import { GET_INITIAL_FILTERS, SET_FOLLOWUP_COUNT, SET_QUALIFIED_COUNT, SET_TODO_COUNT } from "../actions/filters.actions";

const initialState = {
    todoCount: 0,
    followUpCount: 0,
    qualifyCount: 0


};




export const filtersReducers = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_INITIAL_FILTERS:
            // console.log(action.data);
            return {
                ...state,
                filter: action.data
            };
        case SET_TODO_COUNT:
            return {
                ...state,
                todoCount: action.data
            };
        case SET_FOLLOWUP_COUNT:
            return {
                ...state,
                followUpCount: action.data
            };
            case SET_QUALIFIED_COUNT:

            return {
                ...state,
                qualifyCount: action.data
            };



        default:
            return state;
    }
}