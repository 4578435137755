import { GET_TO_DO_LEADS, REMOVE_ELEMENT, SET_TODO_ITEM_PER_PAGE, TO_DO_ISLOADING_FALSE, TO_DO_ISLOADING_TRUE } from "../../actions/Lead_lists/toDoLeads.actions";

const initialState = {
    newLeadData: [],
    isLoading: false,
}

export const toDo_Lead_Reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_TO_DO_LEADS:

            return {
                ...state,
                newLeadData: action.data

            };

        case REMOVE_ELEMENT:
            const id = action.data._id
            const arr = state.newLeadData.filter((obj: any, index) => {
                return obj._id !== id
            })
            return {
                ...state,
                newLeadData: arr


            };
        case TO_DO_ISLOADING_TRUE:
            return {
                ...state,
                isLoading: true
            }
        case TO_DO_ISLOADING_FALSE:
            return {
                ...state,
                isLoading: false
            }
        case SET_TODO_ITEM_PER_PAGE:
            return {
                ...state,
                itemPerPage: action.data
            }

        default:
            return state;
    }
}