import { LOST_ISLOADING_TRUE, LOST_ISLOADING_FALSE, GET_LOST_LEAD_DATA } from "../../actions/Lead_lists/lostLeads.actions";

const initialState = {
    lostLeadData: [],
    isLoading: false
};
export const lostLeadReducers = (state = initialState, action: any) => {
    switch (action.type) {
        case LOST_ISLOADING_TRUE:
            return {
                ...state,
                isLoading: true
            };
        case LOST_ISLOADING_FALSE:
            return {
                ...state,
                isLoading: false
            }
        case GET_LOST_LEAD_DATA:
            return {
                ...state,
                lostLeadData: action.data
            };
        default:
            return state;
    }
}