import { getDateRange ,getLastThreeMonthDateRange} from "../../utils/Common/getDateRange";
import { fromDate, toDate, resetDateRange, searchBy, searchVal } from "../types/dateRange.types";

export const initialState = {
  fromDate: getDateRange().fromDate,
  toDate: getDateRange().toDate,
  searchKey:'',
  searchBy:'',
  extendedFromDate:getLastThreeMonthDateRange().fromDate,
  extendedToDate:getLastThreeMonthDateRange().toDate
};

export const dateRangeReducer = (
  state = initialState,
  action: { type: string; data:  {tDate:string, searchKey:string,searchBy:string} }
) => {
  switch (action.type) {
    case toDate:
      return {
        ...state,
        toDate: action.data.tDate,
        searchKey:action.data.searchKey,
        searchBy:action.data.searchBy,
        extendedToDate:action.data.tDate
      };

    case searchVal:
      
      return {
        ...state,
        searchKey: action.data.searchKey,
      };

      case fromDate:
        return {
          ...state,
          fromDate: action.data,
          extendedFromDate:action.data
        };

      case searchBy:
      return {
        ...state,
        searchBy: action.data.searchBy,
      };

        

    case resetDateRange:
      return {
        ...state,
        fromDate: getDateRange().fromDate,
        toDate: getDateRange().toDate,
      };

    default:
      return state;
  }
};
