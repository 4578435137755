export const GET_SERVICE_DATA = "GET_SERVICE_DATA";
export const GET_DEFAULT_SERVICE_DATA = "GET_DEFAULT_SERVICE_DATA"


export const getServiceData = (data: any) => {
    return ({
        type: GET_SERVICE_DATA,
        data: data
    })
};

export const getDefaultServiceData = (data:any)=>{
    return({
        type:GET_DEFAULT_SERVICE_DATA,
        data:data
    })
}